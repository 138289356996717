<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" md="8" lg="6">
        <h5 class="text-h5 mb-6">Uw gegevens</h5>
        <address-form v-model="shippingAddress" ref="form">
          <v-row no-gutters class="px-3">
            <VTextField class="col col-12 pt-2"
                        label="Telefoonnummer"
                        v-model="phone"/>
            <VTextField v-if="!isLoggedIn"
                        label="E-mailadres"
                        class="required col col-12 pt-2"
                        :rules="[v => !!v || 'E-mailadres is verplicht']"
                        v-model="email"/>
            <v-checkbox color="primary"
                        dense
                        v-model="privacyPolicy"
                        :rules="[v => !!v || 'U moet akkoord gaan met de privacy verklaring']"
                        class="required"
            >
              <template #label>
                <span>Ik ga akkoord met de <PrivacyPolicyDialog/> </span>
              </template>
            </v-checkbox>

            <v-checkbox color="primary"
                        dense
                        v-model="newsletter"
                        label="Ik wil graag de nieuwsbrief ontvangen"/>
          </v-row>
        </address-form>
      </v-col>
    </v-row>
    <v-row>
      <VSpacer/>
      <v-btn color="primary" @click="handlePersonalForm">Naar stap 2</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AddressForm from '@/components/Order/forms/AddressForm.vue';
import { fetch, fetchAddress } from '@/api/profile';
import { Address } from '@/application/models/Address.js';
import { Profile } from '@/application/models/Profile.js';
import PrivacyPolicyDialog from '@/components/dialogs/PrivacyPolicy.vue';

export default {
  name: 'TabPersonal',
  components: {
    PrivacyPolicyDialog,
    AddressForm,
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'authorisation/isLoggedIn',
      getShippingAddress: 'order/shippingAddress',
      getNewsletter: 'order/newsletter',
      getPrivacyPolicy: 'order/privacyPolicy',
      getEmail: 'order/email',
      getPhone: 'order/phone',
    }),
    shippingAddress: {
      get() {
        return this.getShippingAddress;
      },
      set(value) {
        this.setShippingAddress(value);
      },
    },
    newsletter: {
      get() {
        return this.getNewsletter;
      },
      set(value) {
        this.setNewsletter(value);
      },
    },
    privacyPolicy: {
      get() {
        return this.getPrivacyPolicy;
      },
      set(value) {
        this.setPrivacyPolicy(value);
      },
    },
    email: {
      get() {
        return this.getEmail;
      },
      set(value) {
        this.setEmail(value);
      },
    },
    phone: {
      get() {
        return this.getPhone;
      },
      set(value) {
        this.setPhone(value);
      },
    },
  },
  created() {
    this.getPersonalDetails();
  },
  watch: {
    getShippingAddress: {
      deep: true,
      handler() {
        this.setHasFinishedPersonalTab(this.$refs.form.valid);
      },
    },
  },
  methods: {
    ...mapMutations('order', [
      'setShippingAddress',
      'setHasFinishedPersonalTab',
      'setNewsletter',
      'setPrivacyPolicy',
      'setEmail',
      'setPhone',
      'setCreateUser',
    ]),
    ...mapMutations('profile', ['setProfile']),
    async getPersonalDetails() {
      if (!this.isLoggedIn) {
        return;
      } else {
        this.setCreateUser(false);
      }

      const [addressResponse, profileResponse] = await Promise.all([
        fetchAddress(),
        fetch(),
      ]);

      const profile = new Profile().mapResponse(profileResponse?.data?.data);

      this.setProfile(profile);
      this.setEmail(profile.email);
      this.setPhone(profile.phone);
      this.setShippingAddress(new Address().mapResponse({
        ...this.shippingAddress,
        ...addressResponse?.data?.data || {},
      }));
    },
    handlePersonalForm() {
      this.$refs.form.validate();
      this.setHasFinishedPersonalTab(this.$refs.form.valid);

      if (this.$refs.form.valid) {
        this.$emit('nextStep');
      }
    },
  },
};
</script>
