<template>
  <div>
    <span>{{ address.attention }}</span>
    <br/>
    <template v-if="address.company">
      <span>{{ address.company }}</span>
      <br/>
    </template>
    <span class="pr-1">{{ address.street }}</span>
    <span>{{ address.houseNumber }}</span>
    <br/>
    <span>{{ address.postalCode }} </span>
    <span>{{ address.city }}</span>
  </div>
</template>

<script>
export default {
  name: 'Address',
  props: {
    address: {
      required: true,
      type: Object,
    },
  },
};
</script>
